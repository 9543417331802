/* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
*/
@import '@angular/cdk/overlay-prebuilt.css';
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
input,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

body {
  line-height: 1;
  overflow-y: scroll;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

.app-container {
  max-width: 1024px;
  min-height: 100vh;
  margin: auto;
}

.layout {
  margin: 0 2rem;
}

[class^=icon-],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-sort-asc::before {
  content: "\ea4c";
}

.icon-sort-desc::before {
  content: "\ea4d";
}

.icon-filter::before {
  content: "\ea5b";
}

.icon-next::before {
  content: "\e901";
}

.icon-previous::before {
  content: "\e900";
}

.icon-arrow-up::before {
  content: "\e902";
}

.icon-arrow-down::before {
  display: inline-block;
  content: "\e903";
}

.icon-link::before {
  content: "\e904";
}

.icon-checkmark::before {
  content: "\ea10";
}

/* Equivalent to 100 % */
@font-face {
  font-family: "icomoon";
  src: url("/assets/fonts/icomoon.woff") format("woff");
  font-display: block;
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "freight-sans-pro";
  src: local("freight-sans-pro"), url("/assets/fonts/freight-sans-pro.woff2") format("woff2"), url("/assets/fonts/freight-sans-pro.woff") format("woff");
  font-display: swap;
  font-style: normal;
  unicode-range: U+000-5FF;
}
h1 {
  font-size: 20px;
}

h2 {
  font-size: 16px;
}

.text__heading {
  font-weight: bold;
}
.text__heading--paragraph {
  margin-bottom: 0.75rem;
}
.text__heading--small {
  margin-bottom: 1.5rem;
  font-weight: bold;
}

.text__paragraph:not(:only-child) {
  margin: 0 0 0.5rem;
}
.text__paragraph-container {
  margin: 0 0.75rem 1.5rem;
}
.text__paragraph-container--slim {
  margin: 0;
}
.text__paragraph-item {
  margin: 2rem 0.5rem;
  white-space: pre-line;
}

button {
  font-size: 14px;
}

strong {
  font-weight: bold;
  color: black;
}

.sh-panel__container {
  position: relative;
  display: block;
  box-sizing: border-box;
  background-color: #ffffff;
  border: 0.0625rem solid #eae9e8;
  box-shadow: 0 0.125rem 0.375rem 0 rgba(30, 28, 26, 0.05);
  width: 100%;
  margin-top: 0.75rem;
}
.sh-panel__container--dark {
  background-color: #e8eef0;
}
.sh-panel__container--transparent {
  background-color: transparent;
  box-shadow: none;
  border: none;
  flex-basis: 33.3333333333%;
}
.sh-panel__container--transparent:not(:first-of-type) {
  padding-left: 2rem;
}
.sh-panel__container--row {
  display: flex;
  align-items: center;
}
.sh-panel__container--row .sh-panel__header {
  padding: 0 1rem;
}
.sh-panel__header, .sh-panel__content {
  display: block;
  overflow: hidden;
  box-sizing: border-box;
  padding: 1rem;
}
.sh-panel__header--no-pad, .sh-panel__content--no-pad {
  padding: 1rem 0 0;
}
.sh-panel__header--light, .sh-panel__content--light {
  background-color: #ffffff;
}
.sh-panel__header--dark, .sh-panel__content--dark {
  background-color: #e8eef0;
}
.sh-panel__header--wide, .sh-panel__content--wide {
  width: 100%;
}
.sh-panel__header {
  padding-bottom: 0;
  font-weight: bold;
  text-transform: uppercase;
  white-space: nowrap;
}
.sh-panel__header--long {
  min-width: 200px;
}

.sh-select__wrapper {
  position: relative;
  min-width: 5.625rem;
}
.sh-select__wrapper--brown {
  width: 100%;
  height: 50px;
  background-color: #ab9d80;
  color: #ffffff;
  text-transform: inherit;
  display: flex;
  align-items: center;
}

.sh-select__input {
  position: relative;
  display: flex;
  align-items: center;
  overflow: hidden;
  width: 100%;
  height: 100%;
  margin: 0;
}
.sh-select__input--has-label {
  padding-right: 2rem;
}
.sh-select__input-field {
  outline: 0;
  width: 100%;
  height: 1.5rem;
  line-height: 1;
  max-width: 170px;
  border: none;
  background-color: transparent;
  text-overflow: ellipsis;
  white-space: normal;
  overflow: hidden;
  color: #4a494a;
  padding: 0.5rem 1rem;
  cursor: pointer;
}
.sh-select__input-field--brown {
  color: #ffffff;
}
.sh-select__input-field:disabled {
  cursor: default;
  color: #eae9e8;
  border-bottom: solid 0.0625rem #eae9e8;
}
.sh-select__input-field--has-label {
  padding-left: 0;
  text-align: right;
  border-bottom: 0.0625rem solid #eae9e8;
  color: #1d428a;
}
.sh-select__label {
  position: absolute;
  bottom: 0.5rem;
  text-transform: uppercase;
}
.sh-select__dropdown-arrow {
  position: absolute;
  right: 0.75rem;
  top: 43%;
  cursor: pointer;
  font-size: 8px;
  width: 0.75rem;
}
.sh-select__dropdown-options-container {
  width: 100%;
  border-radius: 0.25rem;
  box-shadow: 0 0.125rem 0.5rem 0 rgba(0, 0, 0, 0.2);
  display: block;
  overflow: auto;
}
.sh-select__dropdown-options-container::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 0.375rem rgba(0, 0, 0, 0.3);
  box-shadow: inset 0 0 0.375rem rgba(0, 0, 0, 0.3);
  border-radius: 0.625rem;
  background-color: #f5f5f5;
}
.sh-select__dropdown-options-container::-webkit-scrollbar {
  width: 0.25rem;
  background-color: #f5f5f5;
}
.sh-select__dropdown-options-container::-webkit-scrollbar-thumb {
  border-radius: 0.625rem;
  -webkit-box-shadow: inset 0 0 0.375rem rgba(0, 0, 0, 0.3);
  box-shadow: inset 0 0 0.375rem rgba(0, 0, 0, 0.3);
  background-color: #d2d0d0;
}

app-sh-select-option {
  display: block;
  padding: 0 0.875rem;
  height: 2.5rem;
  line-height: 2.5rem;
  color: #1d428a;
  background-color: #ffffff;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  user-select: none;
  cursor: pointer;
}
app-sh-select-option.sh-select__item--brown {
  background-color: #ab9d80;
  color: #ffffff;
}
app-sh-select-option.sh-select__item:hover {
  outline: none;
  background-color: #1d428a;
  color: #ffffff;
}
app-sh-select-option.sh-select__item--disabled {
  color: #d2d0d0;
  cursor: auto;
}
app-sh-select-option.sh-select__item--disabled:hover, app-sh-select-option.sh-select__item--disabled:focus {
  outline: none;
  background-color: #ffffff;
}

.sh-button__container {
  cursor: pointer;
  display: inline-block;
  position: relative;
  padding: 0.75rem 1rem;
  vertical-align: top;
  text-decoration: none;
  box-sizing: border-box;
  min-width: 12rem;
}
.sh-button__container--small {
  height: 0;
}
.sh-button__container--medium {
  height: 2.5rem;
}
.sh-button__container--large {
  height: 4rem;
}
@media (max-width: 1022px) {
  .sh-button__container {
    height: 2.5rem;
    min-width: 16rem;
  }
}
.sh-button__link {
  -webkit-appearance: none;
  padding: 0;
  border: 0;
  background: none;
  color: #1d428a;
  vertical-align: middle;
  display: inline;
  min-width: auto;
  outline: none;
  white-space: nowrap;
  font-family: "freight-sans-pro", Georgia, sans-serif;
  cursor: pointer;
}
.sh-button__link--has-partition:not(:first-of-type) {
  margin-left: 0.5rem;
}
.sh-button__link--has-partition:not(:last-of-type) {
  margin-right: 0.5rem;
}
.sh-button__link--has-partition + span {
  font-size: 14px;
  vertical-align: middle;
}
.sh-button__primary {
  border: 0.0625rem solid;
}
.sh-button__primary--blue {
  border-color: #1d428a;
  color: #ffffff;
  font-weight: bold;
  background-color: #1d428a;
}
.sh-button__primary--black {
  border-color: #1e1c1a;
  color: #ffffff;
  font-weight: bold;
  background-color: #1e1c1a;
}
.sh-button__primary--brown {
  border-color: #e0ba87;
  color: #1e1c1a;
  font-weight: bold;
  background-color: #e0ba87;
}
.sh-button__primary--white {
  border-color: #ffffff;
  color: #1e1c1a;
  font-weight: bold;
  background-color: #ffffff;
}
.sh-button__primary--is-disabled {
  border-color: #eae9e8;
  color: #d2d0d0;
  font-weight: bold;
  background-color: #eae9e8;
  pointer-events: none;
}
.sh-button__primary-hover {
  border: 0.125rem solid;
}
.sh-button__primary-hover--blue {
  border-color: #1d2b47;
  color: #ffffff;
  font-weight: bold;
  background-color: #1d2b47;
}
.sh-button__primary-hover--black {
  border-color: rgba(30, 28, 26, 0.9);
  color: #ffffff;
  font-weight: bold;
  background-color: rgba(30, 28, 26, 0.9);
}
.sh-button__primary-hover--brown {
  border-color: #ab9d80;
  color: #1e1c1a;
  font-weight: bold;
  background-color: #ab9d80;
}
.sh-button__primary-hover--white {
  border-color: #eae9e8;
  color: #1e1c1a;
  font-weight: bold;
  background-color: #eae9e8;
}
.sh-button__primary-hover--is-disabled {
  border-color: #eae9e8;
  color: #d2d0d0;
  font-weight: bold;
  background-color: #eae9e8;
  pointer-events: none;
}
.sh-button__secondary {
  border: 0.0625rem solid;
}
.sh-button__secondary--blue {
  border-color: #1d428a;
  color: #1d428a;
  font-weight: bold;
  background-color: #ffffff;
}
.sh-button__secondary--black {
  border-color: #1e1c1a;
  color: #1e1c1a;
  font-weight: bold;
  background-color: #ffffff;
}
.sh-button__secondary--brown {
  border-color: #e0ba87;
  color: #e0ba87;
  font-weight: bold;
  background-color: #ffffff;
  color: #1e1c1a;
}
.sh-button__secondary--white {
  border-color: #1e1c1a;
  color: #1e1c1a;
  font-weight: bold;
  background-color: #ffffff;
}
.sh-button__secondary--is-disabled {
  border-color: #d2d0d0;
  color: #d2d0d0;
  font-weight: bold;
  background-color: #ffffff;
  pointer-events: none;
}
.sh-button__secondary-hover {
  border: 0.125rem solid;
}
.sh-button__secondary-hover--blue {
  border-color: #1d428a;
  color: #1d428a;
  font-weight: bold;
  background-color: #ffffff;
}
.sh-button__secondary-hover--black {
  border-color: rgba(30, 28, 26, 0.9);
  color: rgba(30, 28, 26, 0.9);
  font-weight: bold;
  background-color: #ffffff;
}
.sh-button__secondary-hover--brown {
  border-color: #ab9d80;
  color: #ab9d80;
  font-weight: bold;
  background-color: #ffffff;
  color: #1e1c1a;
}
.sh-button__secondary-hover--white {
  border-color: #1e1c1a;
  color: #1e1c1a;
  font-weight: bold;
  background-color: #ffffff;
}
.sh-button__secondary-hover--is-disabled {
  border-color: #d2d0d0;
  color: #d2d0d0;
  font-weight: bold;
  background-color: #ffffff;
  pointer-events: none;
}
.sh-button__primary--is-rounded, .sh-button__secondary--is-rounded {
  border-radius: 1.5rem;
  font-size: 10.5px;
  text-transform: capitalize;
  min-width: 0;
  width: 6.125rem;
  height: auto;
  padding: 0.625rem 1.5rem;
}
.sh-button__text {
  display: flex;
  align-items: center;
  height: 100%;
}
.sh-button__text--with-icon > img,
.sh-button__text--with-icon > i {
  padding: 0 0.5rem;
}
.sh-button__text--text-align-left {
  text-align: left;
  justify-content: flex-start;
}
.sh-button__text--text-align-center {
  text-align: center;
  justify-content: center;
}
.sh-button__text--text-align-right {
  text-align: right;
  justify-content: flex-end;
}
.sh-button__text i,
.sh-button__text img {
  margin-left: auto;
}

.sh-table__container {
  width: 100%;
  height: 100%;
  border: 0.0625rem solid #eae9e8;
}
.sh-table__container--no-border {
  border: none;
}
.sh-table__header {
  text-align: left;
  background-color: #1d428a;
  color: #ffffff;
}
.sh-table__header--transparent {
  background-color: transparent;
  color: #1e1c1a;
}
.sh-table__header-item, .sh-table__content-item {
  padding: 1rem 1.5rem;
}
.sh-table__header-item--link-column, .sh-table__content-item--link-column {
  width: 25%;
}
.sh-table__header-item--link-column .icon, .sh-table__content-item--link-column .icon {
  cursor: pointer;
  float: right;
}
.sh-table__header-item--appl-area-column, .sh-table__content-item--appl-area-column {
  width: 17%;
}
.sh-table__header-item {
  cursor: pointer;
  font-weight: bold;
}
.sh-table__header-item--is-centered {
  text-align: center;
}
.sh-table__header-item--small {
  width: 5%;
}
.sh-table__header-item--disabled {
  cursor: default;
}
.sh-table__header-icon {
  font-size: 10.5px;
  margin-left: 0.125rem;
}
.sh-table__content--no-content {
  text-align: center;
}
.sh-table__content-checkbox {
  vertical-align: middle;
}
.sh-table__content--striped .sh-table__content-row:nth-child(even) {
  background-color: #fafafa;
}
.sh-table__content--striped .sh-table__content-row:nth-child(odd) {
  background-color: #f5f5f5;
}
.sh-table__content-row {
  border-bottom: 0.0625rem solid #eae9e8;
}
.sh-table__content-row:first-child {
  border-top: 0.0625rem solid #eae9e8;
}
.sh-table__row {
  display: table-row;
}

.sh-table-paginator {
  margin: 0.625rem;
  position: relative;
}
.sh-table-paginator__container {
  display: flex;
  justify-content: center;
}
.sh-table-paginator__page-item {
  cursor: pointer;
  padding: 0.3125rem 0.625rem;
}
.sh-table-paginator__page-item--active {
  background-color: #1d428a;
  color: #ffffff;
}
.sh-table-paginator__page-item--hide {
  display: none;
}
.sh-table-paginator__page-size-selector {
  position: absolute;
  top: -0.3125rem;
  right: 0;
  width: 5.625rem;
}

.sh-input__container {
  position: relative;
  padding: 0.75rem 1rem;
  background-color: #ffffff;
  border: 0.0625rem solid #eae9e8;
  height: 1.625rem;
  display: flex;
}
.sh-input__field {
  border: none;
  width: 100%;
  outline: none;
  color: #1d428a;
  font-weight: bold;
}
.sh-input__field::placeholder {
  color: #1e1c1a;
  font-weight: normal;
}
.sh-input__field:focus::placeholder {
  color: #d2d0d0;
}
.sh-input__icon {
  position: absolute;
  right: 1.5rem;
  top: 50%;
  width: 1.5rem;
  height: 1.5rem;
  transform: translateY(-50%);
  pointer-events: none;
}
.sh-input__clear-search {
  width: 2rem;
  height: 2rem;
  pointer-events: unset;
}
@keyframes spinner {
  to {
    transform: rotate(1turn);
  }
}
.sh-input__spinner {
  display: inline-block;
  color: inherit;
  right: 3.5rem;
}
.sh-input__spinner::before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  border-radius: 1.5rem;
  animation: spinner 0.4s infinite linear;
  border: 3px solid currentColor;
  border-left-color: transparent;
}

.sh-filter-select {
  width: 100%;
  height: 50px;
  background-color: #ab9d80;
  color: #ffffff;
  text-transform: inherit;
  display: flex;
  align-items: center;
}
.sh-filter-select__container app-sh-data-filter {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
}
.sh-filter-select__container app-sh-data-filter > :last-child {
  flex-basis: 100%;
}

.progress__container {
  background-color: #b0c1bf;
  width: 100%;
  height: 1rem;
  fill: #224142;
}
.progress__value {
  height: 100%;
  transition: width, 350ms;
}

.sh-expansion-panel__container--border-bottom .sh-panel__container {
  border: none;
  border-bottom: 0.0625rem solid #1d428a;
  color: #1d428a;
  box-shadow: none;
}
.sh-expansion-panel__container--no-border .sh-panel__container {
  border: none;
  box-shadow: none;
}
.sh-expansion-panel__container--collapsed .sh-panel__header {
  padding-bottom: 1rem;
}
.sh-expansion-panel__header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  font-weight: bold;
  text-transform: uppercase;
}
.sh-expansion-panel__header--left {
  flex-direction: row-reverse;
}
.sh-expansion-panel__icon {
  font-size: 8px;
}
.sh-expansion-panel__content {
  overflow-y: auto;
  max-height: 222px;
}

.checkboxes-style--columns {
  columns: 4;
  column-gap: 1rem;
  column-rule: 1px solid #eae9e8;
  column-fill: auto;
  height: 11rem;
}
.checkboxes-style--columns .checkboxes__item {
  color: #1d428a;
  cursor: pointer;
  display: block;
  padding: 0.5rem 0.25rem;
  box-sizing: border-box;
  margin-bottom: 0.25rem;
}
.checkboxes-style--columns .checkboxes__item--checked {
  background-color: #ab9d80;
  color: #ffffff;
}
.checkboxes-style--columns .checkboxes__icon {
  float: right;
  margin-right: 0.5rem;
  font-size: 10.5px;
}
.checkboxes-style--pills {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.checkboxes-style--pills .checkboxes__item {
  color: #999696;
  cursor: pointer;
  display: inline-block;
  padding: 0.5rem 1rem;
  border-radius: 2em;
  box-sizing: border-box;
  margin: 0.25rem;
  border: 0.0625rem solid #eae9e8;
  min-width: 5rem;
  text-align: center;
  font-weight: 500;
  user-select: none;
}
.checkboxes-style--pills .checkboxes__item--checked {
  color: #b36d3e;
  border: 0.0625rem solid #b36d3e;
}

.tabs {
  display: flex;
  flex-flow: row nowrap;
}
.tabs__item {
  display: inline-block;
  margin-right: 2rem;
}
.tabs__item__target {
  cursor: pointer;
  display: block;
  padding: 0 0 0.5rem;
}
.tabs__item__target--active {
  color: #1d428a;
  border-bottom: 0.2rem solid;
}

.sh-counter__container {
  height: 2.5rem;
  margin-top: 0.75rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.sh-counter__is-searching .dot {
  display: inline;
  margin-left: 0.1em;
  margin-right: 0.1em;
  position: relative;
  font-size: 20px;
  opacity: 0;
  animation: showHideDot 400ms ease-in-out infinite;
}
.sh-counter__is-searching .dot-one {
  animation-delay: 100ms;
}
.sh-counter__is-searching .dot-two {
  animation-delay: 200ms;
}
.sh-counter__is-searching .dot-three {
  animation-delay: 300ms;
}
@keyframes showHideDot {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  60% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.product-catalogue__top-filters {
  display: grid;
  grid-template-columns: 33% 1fr;
  grid-template-rows: repeat(2, auto);
  grid-column-gap: 2rem;
}
.product-catalogue__top-filters :nth-child(3) {
  grid-area: 2/1/3/3;
}
.product-catalogue__top-filters .sh-panel__container {
  padding: 0;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 100;
  }
}
.notification {
  align-items: center;
  animation-duration: 350ms;
  animation-name: fadeIn;
  display: flex;
  justify-content: center;
  position: absolute;
  right: 50%;
  top: 0;
  transform: translate(50%);
}
.notification__content {
  min-height: 2rem;
  min-width: 10rem;
  padding: 0.75rem;
  animation: inherit;
}
.notification.is-overlay {
  height: 100vh;
  position: fixed;
  width: 100vw;
  background-color: rgba(255, 255, 255, 0.9);
  z-index: 9999;
}
.notification__message {
  color: #999696;
  font-size: 0.75em;
  font-weight: lighter;
}
.notification__message.is-error {
  color: #c34d39;
}
.notification__progress {
  -webkit-appearance: none;
  height: 5px;
  background: linear-gradient(to bottom, #f5f5f5, #ffffff);
  border-radius: 0.5em;
  animation: inherit;
}
.notification__progress::-webkit-progress-bar, .notification__progress::-moz-progress-bar {
  border-radius: inherit;
  background: linear-gradient(to bottom, #fafafa, #d2d0d0);
}

.language-selector {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 6.25rem;
  padding: 0 1.25rem;
}
.language-selector__select {
  border: none;
  background-color: transparent;
  outline: none;
}

.top-bar {
  background-color: #ffffff;
  border-bottom: 0.1rem solid #eae9e8;
  border-right: 0.1rem solid #eae9e8;
  color: #4a494a;
  z-index: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-flow: row no-wrap;
  position: relative;
  height: 3rem;
}
.top-bar__logo {
  display: flex;
  justify-content: center;
  width: 7rem;
  height: 100%;
  padding: 0 0.5rem;
  box-sizing: border-box;
  background-color: #1d2b47;
}
.top-bar__content {
  flex-grow: 1;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;
}
.top-bar__item {
  border-left: 1px solid #eae9e8;
  background-color: #ffffff;
}

.svg-logo {
  fill: #fafafa;
  width: 5rem;
}

app-language-selector {
  height: 100%;
}

.front-page__container {
  margin-top: 2rem;
  width: 75%;
}

.status-menu {
  position: relative;
  width: 80px;
  height: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
  cursor: pointer;
}
.status-menu__icon {
  pointer-events: none;
  display: flex;
}
.status-menu__icon-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  z-index: 1;
}
.status-menu__icon-container--centered {
  justify-content: center;
}
.status-menu__icon--update svg {
  width: 24px;
  height: 24px;
}
.status-menu__status-bar {
  height: 100%;
  width: 100%;
  background-color: #b3f3c6;
  position: absolute;
  left: -100%;
  top: 0;
}
.status-menu__status-bar-container {
  position: relative;
  height: 3rem;
  display: flex;
  justify-content: center;
  overflow: hidden;
}
.status-menu__status-bar-container--dark {
  background-color: #fafafa;
}
.status-menu__status-bar-container--dropdown {
  height: 6rem;
}
.status-menu__status-bar--loading {
  background-color: #ffffff;
  background: repeating-linear-gradient(to right, #b3f3c6 0%, #ffffff 50%, #b3f3c6 100%);
  background-size: 200% auto;
  background-position: 0 100%;
  animation: gradient 2s infinite;
  animation-fill-mode: forwards;
  animation-timing-function: linear;
}
@keyframes gradient {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: -200% 0;
  }
}
.status-menu__status-bar--complete {
  background-color: #b3f3c6;
}
.status-menu__status-bar--error {
  background-color: #ece15d;
}
.status-menu__message-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.status-menu__message-container--column {
  flex-direction: column;
  margin: 0;
}
.status-menu__message-button {
  background-color: unset;
  position: absolute;
  appearance: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  right: 0;
  border: none;
}
.status-menu-dropdown {
  position: absolute;
  outline: none;
  top: calc(100% + 2px);
  width: 400px;
}
.status-menu-dropdown__status-bar {
  height: 6rem;
  width: 100%;
  background-color: #b3f3c6;
  position: absolute;
  left: -100%;
  top: 0;
}
.status-menu-dropdown__status-bar-message {
  margin-top: 0.5rem;
}
.status-menu-dropdown__status-bar-message--bold {
  font-weight: bold;
}
.status-menu-dropdown__options {
  height: 3rem;
  border: 1px solid #eae9e8;
  border-top: 0;
  background-color: #ffffff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1.5rem;
}
.status-menu-dropdown__options app-sh-offline-toggle {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.status-menu-dropdown__log app-sh-panel {
  margin-top: 0;
}
.status-menu-dropdown__log-entry {
  margin-top: 0.5rem;
}

.main-navigation {
  display: flex;
  justify-content: flex-start;
  height: 3rem;
}
.main-navigation__list {
  display: flex;
  height: 100%;
}
.main-navigation__list-item {
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
}

app-main-navigation {
  width: 100%;
}

.menu-link {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  height: 50%;
  color: inherit;
  font-weight: normal;
  padding-bottom: 0.3rem;
  text-decoration: none;
  margin-left: 1.5rem;
}
@media screen and (max-width: 889px) {
  .menu-link {
    height: 100%;
  }
}
.menu-link--active {
  color: #1d428a;
  position: relative;
  border-bottom: 0.2rem solid;
  padding-bottom: 0.1rem;
}
.menu-link--dimmed {
  background-color: #f5f5f5;
}

.user {
  align-items: center;
  box-sizing: border-box;
  display: flex;
  flex-flow: row nowrap;
  height: 100%;
  cursor: pointer;
}
.user__button {
  position: relative;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  min-width: 9rem;
  font-weight: normal;
  background-color: #ffffff;
  border: 0;
}
.user__name {
  text-transform: uppercase;
  white-space: nowrap;
  margin-right: 2rem;
}
.user__menu {
  position: absolute;
  width: 100%;
  bottom: 0;
  visibility: hidden;
}
.user__menu:hover {
  background-color: #eae9e8;
}
.user__menu-button {
  width: 100%;
  height: 100%;
  outline: none;
}
.user__image {
  position: relative;
  border-radius: 25px;
  border: 1px solid #c34d39;
  margin: 0 0.75rem;
}
.user__image--empty {
  margin-right: 2rem;
}
.user__offline {
  color: #c34d39;
}

.portals {
  height: 100%;
  width: 160px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: relative;
}
.portals__icon {
  position: absolute;
  left: 0.75rem;
  width: 14px;
  height: auto;
}
.portals__icon svg {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  height: 22px;
}
.portals__select {
  width: 100%;
  height: 100%;
}
.portals__select .sh-select__wrapper {
  height: 100%;
}
.portals__select .sh-select__wrapper .sh-select__input {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.portals__select .sh-select__wrapper .sh-select__input-field {
  max-width: 100px;
  padding-left: 3rem;
}

.mat-dialog-container {
  padding: 0 !important;
  position: relative;
  background-color: #ffffff;
  box-shadow: 0 0.125rem 0.375rem 0 rgba(30, 28, 26, 0.05);
}

.product-page {
  position: relative;
}
.product-page__container {
  position: relative;
}
.product-page__close-button {
  position: absolute;
  right: 1.5rem;
  font-size: 20px;
  border: 1px solid black;
  padding: 0.25rem 0.75rem;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 100ms linear;
}
.product-page__close-button:hover {
  background-color: #4a494a;
  color: #ffffff;
}
.product-page__header {
  padding: 1.5rem 2rem;
  background-color: #ffffff;
  position: sticky;
  top: 0;
  z-index: 1;
}
.product-page__title {
  margin-bottom: 1.5rem;
  font-size: 16px;
  font-weight: bold;
}
.product-page__content {
  padding: 0 1.5rem 1.5rem 1.5rem;
}
.product-page__icons {
  display: flex;
}
.product-page__icons > img:not(:first-child) {
  margin-left: 0.5rem;
}
.product-page__profiles {
  position: absolute;
  top: 0;
  right: 2rem;
}

.solution-finder {
  margin-top: 1px;
  padding-bottom: 2rem;
}
.solution-finder mat-table {
  background: unset;
}
.solution-finder mat-table mat-row {
  border: 0;
}
.solution-finder .mat-paginator-icon {
  width: 40px;
}
.solution-finder .mat-option {
  font-size: 14px;
}
.solution-finder__select-options {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 60px;
  margin-bottom: 40px;
  text-transform: uppercase;
}
.solution-finder__item {
  width: 100%;
}
.solution-finder__item-heading {
  font-size: 10.5px;
  margin-bottom: 0.75rem;
}
.solution-finder__item:not(:last-of-type) {
  margin-right: 2rem;
}
.solution-finder__dropdown {
  width: 100%;
  height: 50px;
  background-color: #ab9d80;
  color: #ffffff;
  padding: 0 0.75rem;
  text-transform: inherit;
}
.solution-finder__search {
  width: 100%;
  height: 50px;
  padding: 0 0.75rem;
  text-transform: inherit;
}
.solution-finder__heading {
  font-size: 16px;
  margin: 1.5rem 0;
}
.solution-finder__recipe {
  display: block;
  width: 100%;
  margin: 0.75rem 0;
  background-color: #ffffff;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.05);
  padding-bottom: 2rem;
}
.solution-finder__recipe-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #e8eef0;
  padding: 1.5rem 1.5rem;
}
.solution-finder__recipe-header-title {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 50%;
  height: 100%;
}
.solution-finder__recipe-header-title-text {
  font-weight: bold;
  font-size: 16px;
}
.solution-finder__recipe-table {
  margin: 1.5rem 0 0 1.5rem;
}
.solution-finder__recipe-table-header {
  font-size: 14px;
  font-weight: bold;
}
.solution-finder__recipe-table-cell {
  padding: 1rem 1.5rem;
  text-align: left;
}
.solution-finder__recipe-content-products {
  margin: 0 2rem;
}

.marketing-files {
  margin-top: 1px;
  padding-bottom: 2rem;
}
.marketing-files mat-table {
  background: unset;
}
.marketing-files mat-table mat-row {
  border: 0;
}
.marketing-files__file-links {
  display: flex;
  flex-direction: row;
  gap: 20px;
}
.marketing-files__file-link {
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.marketing-files__file-link-text {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 20px;
}
.marketing-files__file-link-text-icon {
  display: flex;
  align-items: center;
}
.marketing-files__file-link-text-icon svg {
  width: 25px;
  height: 25px;
}
.marketing-files__file-link-icon {
  display: flex;
  align-items: center;
}
.marketing-files__file-link-icon svg {
  width: 25px;
  height: 25px;
  rotate: 90deg;
}

.sh-divider {
  display: block;
  margin: 0;
  border-top: 0.0625rem solid rgba(0, 0, 0, 0.12);
}

.sh-document-viewer {
  position: relative;
  width: 100%;
  height: 100%;
}
.sh-document-viewer__download-button {
  position: absolute !important;
  top: 1.5rem;
  left: 50%;
  transform: translateX(-50%);
  z-index: 999;
  background-color: #1d2b47 !important;
}
.sh-document-viewer__download-button-icon svg {
  width: 50%;
  height: 50%;
}
.sh-document-viewer__spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

body {
  font-family: "freight-sans-pro", Georgia, sans-serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 1.3;
  background-color: #fafafa;
  color: #4a494a;
  font-feature-settings: "lnum" 1;
}

.element-hidden {
  display: none;
}

.container {
  position: relative;
  display: flex;
  flex-flow: row nowrap;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}
.container__content {
  display: flex;
  flex-direction: row;
}
.container__main-content {
  padding: 2rem;
  background-color: #fafafa;
}

img {
  vertical-align: middle;
}

hr {
  border: 0.5px solid #eae9e8;
}

mat-table,
input {
  font-family: "freight-sans-pro", Georgia, sans-serif !important;
}

ul.list,
ol.list {
  list-style: disc;
  margin: 0;
  padding: 0;
}
ul.list li,
ol.list li {
  margin: 0 0 0 2rem;
  padding: 0;
}

.sh-mat-drawer {
  width: 80vw;
  height: 100vh;
}

.vimeo-player-modal {
  width: 100%;
}